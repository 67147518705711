.dropdown-search {
    width: calc(100% - 32px);
    padding: 8px;
    margin: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
}

.dropdown-menu {
    display: block;
    position: absolute;
    max-height: 250px;
    background-color: #f9f9f9;
    min-width: 160px;
    overflow: scroll;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
}

.dropdown-menu span {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-menu span:hover {
    background: #075da8;
    color: #fff;
    cursor: pointer;
}