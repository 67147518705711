.sd-question__content {
    .iti.iti--allow-dropdown.iti--show-flags {
        display: block;
    }
}

.custom_widget_phone {
    width: calc(100% - 58px) !important;
}

.custom-widget-label-sublabel {
    .label-title {
        color: var(--sjs-font-pagetitle-color, var(--sjs-general-dim-forecolor, rgba(0, 0, 0, 0.91)));
        margin: 0 0 calc(0.5*(var(--sjs-base-unit, var(--base-unit, 8px)))) 0px;
    }

    .label-description {
        color: var(--sjs-font-pagedescription-color, var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45)));
    }
}

.swal-confirm-info-text {
    color: #686868;
    // background-color: #cce5ff;
    border-color: #b8daff;
    font-size: 12px;
    // padding: 10px 5px;
}

[aria-label="lookupfield"] ~ .sd-dropdown_chevron-button {
    display: none;
}

[aria-label="multilookupfield"] ~ .sd-dropdown_chevron-button {
    display: none;
}

.sv-list .secondary-label {
    display: block;
    font-size: 0.75rem;
    color: #888;
}


[aria-label="multilookupfield"] .sd-dropdown__value {
    padding-left: 35px !important;
}

[aria-label="lookupfield"] .sd-dropdown__value{
    padding-left: 35px !important;
}

[aria-label="lookupfield"] input {
	padding-left: 25px;
}

[aria-label="lookupfield"],
[aria-label="multilookupfield"] {
    background-image: url("../../search-icon-2.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    /* padding: 12px 20px 12px 40px; */
}

    
/* You can add your custom css here.*/
.sv-list__empty-text {
    display: none;
}

.sv-list__empty-container {
    position: relative;
    height: 96px;
}
.sv-list__empty-container::after {
    content: " ";
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_885_24957)'%3E%3Cpath opacity='0.1' d='M24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40ZM24 12C17.38 12 12 17.38 12 24C12 30.62 17.38 36 24 36C30.62 36 36 30.62 36 24C36 17.38 30.62 12 24 12Z' fill='black' fill-opacity='0.91'/%3E%3Cpath d='M10 26C8.9 26 8 25.1 8 24C8 15.18 15.18 8 24 8C25.1 8 26 8.9 26 10C26 11.1 25.1 12 24 12C17.38 12 12 17.38 12 24C12 25.1 11.1 26 10 26Z' fill='%2319B394'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_885_24957'%3E%3Crect width='32' height='32' fill='white' transform='translate(8 8)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 64px 64px;
    animation-name: rotationAnimation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}