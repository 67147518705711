.loading {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1010999;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.preloader {
    overflow: hidden;
    z-index: 999999999999999999999;
    font-weight: 100;
}

.is-absolute--fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.tp-preloader-ovr {
    opacity: 0.99;
}
.gjs-one-bg1,
.spinner {
    background-color: #2e3037;
}
.tp-preloader-box {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    max-width: 500px;
    left: 0;
    text-align: center;
    right: 0;
    font-size: 1.5rem;
    padding-bottom: 75px;
}
.spinner--bottom {
    bottom: 0;
}
.spinner {
    box-shadow: 0 0 15px rgb(0 0 0 / 2%);
    background-color: #43444b;
    display: none;
    z-index: 2000;
    padding: 5px 5px 2px;
    border-radius: 100%;
    position: absolute;
    bottom: 35%;
    left: 44%;
    opacity: 0.9;
}
.tp-spinner-large {
    display: block;
    background-color: transparent;
    border-color: transparent rgb(23, 141, 247) rgb(23, 141, 247) transparent;
    border-style: solid;
    border-width: 3px;
    border-radius: 150px;
    width: 50px;
    height: 50px;
    margin-bottom: 3px;
    -webkit-animation: spin 0.6s linear infinite;
    animation: spin 0.5s linear infinite;
}