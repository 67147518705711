.setting-gray-bx {
	background-color: #f0f2fa;
	width: 150px;
}

.setting-link a {
	background-color: #f0f2fa;
	font-size: 13px;
	color: #075da8;
	padding: 8px 16px;
	display: block;
	text-decoration: none;
}

.setting-link a.active {
	background-color: #075da8;
	color: #fff;
}

.setting-link a:hover {
	background-color: #075da8;
	color: #fff;
}

.email-setting-tabs .svc-tabbed-menu-item--selected {
	background-image: url("../../polygon-arrow.png");
	background-color: #075da8;
	color: #fff;
	box-shadow: none;
	border-bottom: 0px;
	font-weight: normal;
	line-height: normal;
	background-size: 11px;
	background-position: center 0px;
	background-repeat: no-repeat;
}

.email-setting-tabs .svc-tabbed-menu-item {
	border: 1px solid #075da8;
	padding: 4px 20px;
	position: relative;
	overflow: visible;
	z-index: 1;
}

.email-setting-tabs .svc-tabbed-menu-item:hover,
.email-setting-tabs .svc-tabbed-menu-item:focus {
	background-color: #075da8;
	color: #fff;
	border-bottom: 0px;
	box-shadow: none;
}

.email-setting-tabs .tab-seperator::before {
	background-color: var(--sjs-border-default, var(--border, #d6d6d6));
	position: absolute;
	content: "";
	width: 0;
	height: calc(100% - 12px);
	left: 0;
	top: 6px;
}

.svc-tabbed-menu-item-container {
	position: relative;
}

.email-setting-tabs .svc-tabbed-menu-item .svc-tabbed-menu-item__text {
	// color: #075DA8;
}

.email-setting-tabs.svc-tabbed-menu-wrapper {
	flex: 1 1 auto;
	overflow: visible;
}

.email-setting-tabs .svc-tabbed-menu {
	display: flex;
	white-space: nowrap;
	overflow: visible;
	align-items: center;
	height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-tabbed-menu-1 {
	position: relative;
}

.email-setting-tabs .svc-tabbed-menu-item--selected:after,
.email-setting-tabs .svc-tabbed-menu-item--selected:before {
	top: 30px;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.email-setting-tabs .svc-tabbed-menu-item--selected:after {
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #075da8;
	border-width: 21px;
	margin-left: -21px;
}

.email-setting-tabs .svc-tabbed-menu-item--selected:before {
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #075da8;
	border-width: 21px;
	margin-left: -21px;
}

.email-setting-tabs .svc-text {
	color: #fff;
}

#scrollableDiv-settings,
#scrollableDiv-publish {
	width: auto;
	// background-color: #fff;
	user-select: text !important;

	.svc-toolbox {
		direction: ltr;
		width: 263px;

		.menu-item {
			cursor: pointer;
			color: #fff;
			padding: 10px 5px;
			font-size: 14px;
			border-radius: 2rem;
			transition: 0.1s ease-in-out;
			padding: 0.6rem;

			&.active {
				background-color: #fff;
				overflow: hidden;
				color: #075da8;
			}

			span {
				padding: 4px;
			}
		}
	}

	.required {
		color: red;
	}

	.styles-module_tooltip__mnnfp {
		padding: 8px 16px;
		border-radius: 3px;
		font-size: 90%;
		width: 250px;
	}

	.svc-tab-designer {
		// padding: 20px;
		background: #fff;

		.tab-content {
			// background-color: #fff;
			padding: 0px 25px 0px 25px;
			width: 100%;

			.svc-tabbed-menu-wrapper {
				box-shadow: none;
				margin-bottom: 1rem;

				.svc-tabbed-menu {
					margin-bottom: -1px;
				}
			}

			.settings-header {
				color: var(--primary);
				display: flex;
				justify-content: space-between;
				margin-top: 15px;
				align-items: center;
				margin-bottom: 10px;

				.header-title {
					font-weight: bold;
				}
			}

			.form-group {
				margin-bottom: 1rem;

				label {
					line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
					font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
					font-family: var(--sjs-font-family, var(--font-family));
					color: var(--sjs-general-forecolor-light, var(--foreground-light, #000));
					font-weight: 600;
					margin-bottom: 0.35rem;
					display: block;
					font-size: 14px;
				}

				.form-control {
					border: 1px solid #d1d1d1;
					border-radius: 3px;
					box-shadow: none;
					display: block;
					padding: 10px 12px;
					background-color: var(--sjs-general-backcolor, var(--background, #fff));
					box-sizing: border-box;
					width: 100%;
					height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
					border: none;
					box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
					padding: calc(1 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
					outline: none;
					font-size: calc(1.9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
					font-family: var(--sjs-font-family, var(--font-family));
					color: var(--sjs-general-forecolor, var(--foreground, #161616));
					transition: all 0.2s;

					&:focus {
						box-shadow: inset 0 0 0 2px var(--sjs-primary-backcolor, var(--primary, #19b394));
					}
				}

				select {
					position: relative;

					&.form-control {
						-webkit-appearance: none;
						appearance: none;
						-moz-appearance: none;
						background-color: #fff;
						background-image: url(../../caret.svg);
						background-repeat: no-repeat;
						background-position: right 0.75rem center;
						background-size: 16px 16px;
						padding-right: 2rem;
						border: 1px solid #d1d1d1;
						border-radius: 3px;
						box-shadow: none;
					}

					// &::after{
					//     content: "v";
					//     position: absolute;
					//     right: .5rem;
					//     top:.5rem
					// }
				}

				.small {
					font-size: 0.8rem;
				}

				.mb-2 {
					margin-bottom: 0.35rem;
				}
			}

			.settings-form {
				width: 50%;
				padding-top: 15px;

				&.lg {
					width: 100% !important;
				}

				&.w-100 {
					width: 100% !important;
				}

				.fw-70 {
					width: 70% !important;
				}

				.fw-50 {
					width: 50% !important;
				}

				.fw-30 {
					width: 30% !important;
				}
			}

			.form-cb-group {
				label {
					color: var(--sjs-general-forecolor-light, var(--foreground-light, #5a5c69));
				}

				margin-bottom: 0.875rem;

				.small {
					font-size: 0.8rem;
				}
			}

			.btn {
				
				background: var(--primary);
				color: #fff;
				outline: none;
				border: none;
				cursor: pointer;
				font-weight: 600;
				line-height: 1.5;
				/*box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));*/
				border-radius: var(--sjs-corner-radius, 4px);

				&.slick {
					height: 30px;
				}

				&.danger {
					background: var(--error-color);
				}

				&.danger:hover {
					background: #e56b6b;
				}

				&.success {
					background: var(--selection-border-color);
				}

				&.success:hover {
					background: rgb(20, 164, 139);
				}

				&:hover {
					background: #075497;
				}

				&.right {
					float: right;
				}

				&.outline {
					margin-right: 5px;
					color: var(--primary);
					background: #fff;
					border: 1px solid var(--primary);
				}

				&.outline:hover {
					color: #fff;
					background: var(--primary);
					border: none;
				}
			}

			.shadow {
				box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
			}

			.rounded {
				border-radius: 0.25rem !important;
			}
		}

		.error {
			color: var(--error-color);
			font-size: 12px;
		}

		.d-flex {
			display: flex !important;
		}

		.justify-content-between {
			justify-content: space-between;
			align-items: center;
		}

		.justify-content-start {
			justify-content: start;
			align-items: center;
		}

		.mb-2 {
			margin-bottom: 1rem;
		}

		.mr-2 {
			margin-right: 1rem;
		}

		.mr-4 {
			margin-right: 2rem;
		}

		.mt-2 {
			margin-top: 1rem;
		}

		.mt-4 {
			margin-top: 2rem;
		}

		.text-primary {
			color: var(--primary);
		}

		.border {
			border: 1px solid #dee2e6 !important;
		}

		.pb-setting {
			padding: 15px;

			.small {
				margin-top: 6px !important;
				// color: rgb(138, 138, 138)
			}
		}

		.domain-url {
			border: none;
			box-shadow: inset 0 0 0 1px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
			padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
			outline: none;
			font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
			font-family: var(--sjs-font-family, var(--font-family));
			color: var(--sjs-general-forecolor, var(--foreground, #161616));
			background: #f7f7f7;
			white-space: nowrap;
			// overflow: hidden;
			// text-overflow: ellipsis;

			&.copy {
				cursor: pointer;
				min-width: 3rem;
				text-align: center;

				.fa.fa-check-circle {
					color: var(--selection-border-color);
				}
			}
		}

		.editor-screen {
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			background: #eee;
			z-index: 999;
		}

		.dropdown-like-btn {
			background: #fff;
			height: 25px;
			color: var(--primary);
			font-weight: normal;
			border: 0 solid #248ed9;
			border-radius: 0.2rem;
			outline: none;
			font-size: 14px;
			cursor: pointer;

			&:hover {
				background: transparent;
			}
		}

		@import "general-settings";
		@import "email-settings";
	}

	.dropdown-menu {
		min-width: 230px;
		background: #fff;
		overflow: auto;
		padding-top: 10px;
	}

	.chip {
		color: white;
		margin-left: 0.75rem;
		font-size: 0.75rem;
		border-radius: 16px;
		padding: 0 10px;
		display: inline-block;

		&.unpublished {
			background-color: var(--error-color);

			&:hover {
				background-color: var(--error-color);
			}
		}

		&.published {
			background-color: var(--selection-border-color);

			&:hover {
				background-color: var(--selection-border-color);
			}
		}
	}

	.preview-screen {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		inset: 0px;
		background: rgb(255, 255, 255);
		z-index: 999;

		.topbar {
			display: flex;
			padding: 5px 15px;
			align-items: center;
			justify-content: space-between;
			border: 1px solid var(--border-color, #f7f7f7);

			.devices {
				cursor: pointer;
				margin: 3px;
				padding: 3px 6px;
				font-size: 16px;

				&.active {
					background-color: #e7e7e7;
				}
			}

			.close {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 20px;
				cursor: pointer;
				width: 20px;
			}
		}

		.frame-container {
			border: none;
			margin-top: 5px;
			display: flex;
			justify-content: center;
			// align-items: center;
			background: #eee;
			max-height: calc(100vh - 45px);
			overflow: auto;

			iframe {
				border: 1px solid var(--border-color, #f7f7f7);
				background: white;
			}
		}
	}
}

.optimiser-integration-tab {
	width: 75%;

	@media (min-width: 1200px) {

		.container,
		.container-lg,
		.container-md,
		.container-sm,
		.container-xl {
			max-width: 1140px;
		}
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 30px;
		height: 17px;
		margin-bottom: 0px !important;
	  }
	  
	  .switch input { 
		opacity: 0;
		width: 0;
		height: 0;
	  }
	  
	  .slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
	  }
	  
	  .slider:before {
		position: absolute;
		content: "";
		height: 13px;
		width: 13px;
		left: 2px;
		bottom: 2px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	  }
	  
	  input:checked + .slider {
		background-color: #2196F3;
	  }
	  
	  input:focus + .slider {
		box-shadow: 0 0 1px #2196F3;
	  }
	  
	  input:checked + .slider:before {
		-webkit-transform: translateX(13px);
		-ms-transform: translateX(13px);
		transform: translateX(13px);
	  }
	  
	  /* Rounded sliders */
	  .slider.round {
		border-radius: 17px;
	  }
	  
	  .slider.round:before {
		border-radius: 50%;
	  }
	.section-delete{
		text-align:right;
		width:100%;
	}
	.row {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -15px;
		// margin-left: -15px;
	}

	.col {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.pt-2,
	.py-2 {
		padding-top: 0.5rem !important;
	}

	.form-group {
		margin-bottom: 1rem;
	}

	.p-4 {
		padding: 1.5rem !important;
	}

	.mt-4,
	.my-4 {
		margin-top: 1.5rem !important;
	}

	.rounded {
		border-radius: 0.25rem !important;
	}

	.bg-light {
		background-color: #f8f9fa !important;
	}

	label {
		display: inline-block;
		margin-bottom: 0.5rem;
	}

	.rounded-sm {
		border-radius: 0.2rem !important;
	}

	.text-right {
		text-align: right !important;
	}

	.pt-3,
	.py-3 {
		padding-top: 1rem !important;
	}

	.text-left {
		text-align: left !important;
	}

	.add-object-btn {
		width: 134px !important;
	}

	.clear {
		clear: both;
	}

	// .form-sec {
	// 	margin-bottom:0px;
	// }
	.Optimiser-bx {
		float: left;
		width: 42%;
		margin-right: 30px;
	}
	.Optimiser-bx2 {
		float: left;
		width: 38%;
		margin-right: 30px;
	}
	.field-bx {
		float: left;
		width: 42%;
		margin-right: 30px;
	}
	.field-bx2 {
		float: left;
		width: 38%;
		margin-right: 30px;
	}

	.icon-bx {
		float: left;
		width: 6%;
		margin-right: 0px;
	}
	.icon-bx2 {
		float: left;
		width: 14%;
		margin-right: 0px;
	}
	.add-field-bx {
		width: 100%;
		margin-top: 20px;
		text-align: right;
	}

	// .form-control {
	// 	display: block;
	// 	width: 100%;
	// 	height: calc(1.1em + 0.75rem + 2px);
	// 	padding: 0.12rem 0.75rem;
	// 	font-size: 14px;
	// 	font-weight: 400;
	// 	line-height: 1.5;
	// 	color: #495057;
	// 	background-color: #fff;
	// 	background-clip: padding-box;
	// 	border: 1px solid #ced4da;
	// 	border-radius: 0.25rem;
	// 	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	// }
	.btn-primary {
		background: #075da8 !important;
		color: #fff !important;
		border-color: #075da8 !important;
		border-radius: 5px !important;
		font-size: 1rem;
		padding: 7px 24px 7px 24px !important;
		width: unset !important;
	}

	.btn-primary:hover {
		background: #0e69b8 !important;
		color: #fff !important;
		border-color: #0e69b8 !important;
	}

	.btn-secondary {
		background: #858796 !important;
		color: #fff !important;
		border-color: #858796 !important;
		border-radius: 5px !important;
		font-size: 1rem;

		padding: 7px 24px 7px 24px !important;
	}

	.btn-secondary:hover {
		background: #707176 !important;
		color: #fff !important;
		border-color: #707176 !important;
	}

	.trash-icon {
		color: #9a9a9a;
		font-size: 1.2em;
	}

	.trash-icon a {
		color: #9a9a9a;
		
	}

	.with-border {
		display: inline-block;
		padding: 10px 20px;
		font-size: 16px;
		cursor: pointer;
		border-radius: 5px;
		text-align: center;
		text-decoration: none;
		transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
		height: 38px;
		font-weight: 600;
	}

	.btn-outline {
		background: #fff !important;
		color: #075da8 !important;
		border-color: #075da8 !important;
		border-radius: 5px !important;
		font-size: 1rem;
		padding: 7px 24px 7px 24px !important;
	}

	.btn-outline:hover {
		background: #075da8 !important;
		color: #fff !important;
		border-color: #075da8 !important;
	}

	.font-weight-500 {
		font-weight: 500;
	}

	.d-flex {
		justify-content: flex-start;
		/* Align items at both ends */
		align-items: center;
		/* Center items vertically */
	}

	.section-for-update {
		margin-top: 15px;
		background-color: #fff;
		padding: 15px;
		border-radius: 5px;
		border: 1px solid #d8d8d8;
		margin-bottom: 24px;
	}

	.object-sec {
		margin-top: 10px;
	}

	.d-inline-block {
		display: inline-block;
	}

	.mb-0 {
		margin-bottom: 0px !important;
	}

	.mb-15 {
		margin-bottom: 15px;
	}

	.mt-0 {
		margin-top: 0px;
	}

	.mr-8 {
		margin-right: 8px;
	}

	.d-flex-2 {
		justify-content: space-between;
		align-items: center;
	}

	a.back-btn {
		font-size: 16px;
		color: #075da8;
		border: 1px solid #075da8;
		border-radius: 5px;
		padding: 7px;
		text-decoration: none;
	}

	a.back-btn i {
		margin-right: 3px;
		font-size: 14px;
	}

	.text-primary{
		color: #075da8;
	}
	.text-grey{
		color: #858796;
	}
	@media (max-width: 767px) and (min-width: 320px) {}

	@media (max-width: 1039px) and (min-width: 992px) {}

	@media (min-width: 768px) and (max-width: 960px) {}

	@media (min-width: 1024px) and (max-width: 1366px) {}
}